import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import jpg_mount_cook_mountain from "../images/sliders/mount-cook-mountain.jpg"
import jpg_mount_cook_tourist from "../images/sliders/mount-cook-tourist.jpg"

const IndexPage = () => (
  <>
    <SEO title="awesomeNZ" />
    <Layout
      layoutType="typography HomePage"
      slideshow={
        <ul class="slideshow-slides">
          <li class="slideshow-slide first">
            <img src={jpg_mount_cook_mountain} alt="" class="first image" />
          </li>
          <li class="slideshow-slide ">
            <img src={jpg_mount_cook_tourist} alt="" class="last image" />
          </li>
        </ul>
      }
    />
  </>
)

export default IndexPage
